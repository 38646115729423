import firebases from 'firebase'
import 'firebase/database'

// Your web app's Firebase configuration
const config = {
  apiKey: 'AIzaSyATj2edNlIwH2w8JxPM2V32MH11Z57OtNI',
  authDomain: 'antrian-syafira.firebaseapp.com',
  projectId: 'antrian-syafira',
  storageBucket: 'antrian-syafira.appspot.com',
  messagingSenderId: '816091183034',
  appId: '1:816091183034:web:a93da220bb86df7e9c8f83',
  measurementId: 'G-6TJWZR69TD',
  databaseURL: 'https://antrian-syafira-default-rtdb.firebaseio.com/'
}
if (!firebases.apps.length) {
  firebases.initializeApp(config)
}
export default firebases
