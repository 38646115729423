
<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      :persistent="persistentDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div style="font-size: 17px" class="mb-3" v-html="message" />
          <h2>No Kartu BPJS : {{ memberCard }}</h2>

          <v-progress-linear
            v-show="progress"
            color="primary"
            indeterminate
            rounded
            height="6"
          />
          <v-list two-line>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-account-outline </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ nama }}</v-list-item-title>
                <v-list-item-subtitle>Nama Lengkap</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-numeric </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ nomorSep }}</v-list-item-title>
                <v-list-item-subtitle>Nomor SEP</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-send </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ noRujukan }}</v-list-item-title>
                <v-list-item-subtitle>Nomor Rujukan</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-diabetes </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ diagnosa }}</v-list-item-title>
                <v-list-item-subtitle>Diagnosa</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-hospital-box </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ poli }}</v-list-item-title>
                <v-list-item-subtitle>Poli</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-calendar-alert </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  tglRujukan | moment("DD MMMM YYYY")
                }}</v-list-item-title>
                <v-list-item-subtitle>Tanggal Rujukan</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-calendar-alert </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  tglSep | moment("DD MMMM YYYY")
                }}</v-list-item-title>
                <v-list-item-subtitle>Tanggal SEP</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-card-account-details-star </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  tglCetakKartu | moment("DD MMMM YYYY")
                }}</v-list-item-title>
                <v-list-item-subtitle
                  >Tanggal Cetak Kartu BPJS</v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-card-account-details-star </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  tgl_30
                }} Hari</v-list-item-title>
                <v-list-item-subtitle
                  >Rawat Jalan Kontrol </v-list-item-subtitle
                >
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-check-bold </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ statusPeserta }}</v-list-item-title>
                <v-list-item-subtitle>Status Kartu BPJS</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-check-bold </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ provUmum }}</v-list-item-title>
                <v-list-item-subtitle>Faskes Pertama</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="green darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            {{ negativeBtnLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { apiKedua } from '../plugins/supports'

import { apiKedua } from '../plugins/supports'

export default {
  name: 'Dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tutup',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: true
    },
    message: {
      type: String,
      default: '',
      required: true
    },
    progress: {
      type: Boolean,
      default: false,
      required: false
    },
    memberCard: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      nama: '',
      tglCetakKartu: '',
      statusPeserta: '',
      provUmum: '',
      nomorSep: '',
      tglSep: '',
      noRujukan: '',
      poli: '',
      diagnosa: '',
      tgl_30: '',
      tglRujukan: ''
    }
  },
  methods: {
    getDataPeserta () {
      this.nama = ''
      this.tglCetakKartu = ''
      this.statusPeserta = ''
      this.provUmum = ''
      console.log('DARI PROPS ' + this.memberCard)
      apiKedua.get('emr/cek-kartu?no_kartu=' + this.memberCard).then((r) => {
        const { con, results } = r.data
        if (con) {
          console.log('HASIL ' + results)
          var peserta = results.peserta
          console.log(peserta.nama)
          setTimeout(() => {
            this.nama = peserta.nama
            this.tglCetakKartu = peserta.tglCetakKartu
            this.statusPeserta = peserta.statusPeserta.keterangan
            this.provUmum = peserta.provUmum.nmProvider
          }, 500)
          // this.nama = results.
        } else {
        }
      })
    },
    getSep () {
      apiKedua.get('emr/cek-sep?no_kartu=' + this.memberCard).then((r) => {
        const { con, results } = r.data

        if (con) {
          var kartu = results.sep
          var rujukan = results.rujukan
          var kurang = results.kurang
          console.log('Hasil Kartu ' + kurang)
          setTimeout(() => {
            this.nomorSep = kartu.noSep
            this.tglSep = kartu.tglSep
            this.noRujukan = kartu.noRujukan
            this.poli = kartu.poli
            this.diagnosa = kartu.diagnosa
            this.tglRujukan = rujukan.rujukan.tglKunjungan
            this.tgl_30 = kurang
          }, 300)
        }
      })
    }
  },
  created () {
    // console.log('DARI PROPS' + this.memberCard)
    // setTimeout(() => {
    //   this.getDataPeserta()
    // }, 1000)
  }
}
</script>

  <style scoped>
</style>
