<template>
  <v-container fluid>
    <fieldset class="scheduler-border">
      <legend class="scheduler-border">
        <h3 class="mt-4 primary--text">Data Pasien Rawat Jalan</h3>
      </legend>
      <v-row class="mt-1">
        <v-col cols="12" md="12">
          <v-card class="p-3">
            <v-card-title>
              <v-row>
                <v-col cols="12" md="4" lg="4" sm="4">
                  <v-text-field
                    v-model="dataTable.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" lg="4" sm="4">
                  <v-text-field
                    v-model="dataTable.tglPelayanan"
                    label="Tanggal Pelayanan"
                    outlined
                    dense
                    hide-details="auto"
                    type="date"
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-data-table
              :loading="dataTable.isLoading"
              :headers="dataTable.headerData"
              :search="dataTable.search"
              :tglPelayanan="dataTable.tglPelayanan"
              :items="dataTable.datas"
              :sort-by.sync="dataTable.config.table.sortBy"
              :sort-desc.sync="dataTable.config.table.sortDesc"
              :items-per-page="dataTable.config.table.itemsPerPage"
              :page.sync="dataTable.config.table.page"
              :server-items-length="dataTable.serverLength"
              :options.sync="dataTable.options"
              height="550pt"
              item-key="id"
              :item-class="itemRowBackground"
              multi-sort
              hide-default-footer
              fixed-header
              @page-count="dataTable.config.table.pageCount = $event"
              @pagination="dataTable.pagination = $event"
            >
              <template #[`item.birth_date`]="{ item }">
                {{ item.birth_date | moment("DD MMMM YYYY") }}
              </template>
              <template #[`item.no`]="{ index }">
                {{
                  index +
                  (dataTable.config.table.page - 1) *
                    dataTable.config.table.itemsPerPage +
                  1
                }}
              </template>
              <template #[`item.service_date`]="{ item }">
                {{ item.service_date | moment("DD MMMM YYYY") }}
              </template>
              <template #[`item.patient_name`]="{ item }">
                <b class="m-2" style="font-size: 15px"
                  >{{ item.salutation }} {{ item.patient_name }}</b
                >
                <!-- <br />
              <v-btn
                small
                dark
                color="blue darken-2"
                class="m-1"
                @click="panggil(item)"
                ><v-icon>mdi-play</v-icon>Panggil</v-btn
              ><v-btn
                small
                dark
                color="green"
                @click="selesai(item)"
                >Selesai </v-btn
              > -->
                <!-- &nbsp;<v-btn
                small
                dark
                color="red"
                @click="batal(item)"
              >
                Batal
              </v-btn> -->
              </template>
              <template #[`item.medrec_no`]="{ item }">
                <b>{{ item.medrec_no }}</b>
              </template>
              <template #[`item.staff_name`]="{ item }">
                <b
                  ><span color="red">{{ item.unit_name }}</span> -
                  {{ item.staff_name }}</b
                >
              </template>
              <template #[`item.folio_id`]="{ item }">
                <v-chip color="deep-purple" dark>
                  <b>{{ item.registration_no }}</b>
                </v-chip>
              </template>

              <template #[`item.aksi`]="{ item }">
                <v-btn
                  x-small
                  fab
                  dark
                  :disabled="item.disabled_button"
                  color="blue"
                  @click="goToProfilPasien(item)"
                >
                  <v-icon>mdi-pencil</v-icon> </v-btn
                >&nbsp;<v-btn
                  x-small
                  fab
                  dark
                  :disabled="
                    item.pay_by_classification == 'BPJS' ? false : true
                  "
                  color="green"
                  @click="checkKartuBpjs(item)"
                >
                  <v-icon>mdi-checkbox-marked-circle-plus-outline</v-icon>
                </v-btn>
                &nbsp;
                <!-- <v-btn x-small fab dark color="blue" @click="panggil(item)">
                  <v-icon>mdi-send</v-icon>
                </v-btn> -->
                <!--              &nbsp;-->
                <!--              <v-btn-->
                <!--                x-small-->
                <!--                fab-->
                <!--                dark-->
                <!--                color="blue"-->
                <!--                @click="kirim(item)"-->
                <!--              >-->
                <!--                <v-icon>mdi-send</v-icon>-->
                <!--              </v-btn>-->
                <!-- &nbsp;
              <v-btn
                class="text-white"
                @click="orderLabor(item)"
                color="indigo"
                x-small
                fab
              >
                <v-icon>mdi-beaker-plus-outline</v-icon>
              </v-btn>
              &nbsp;<v-btn
                x-small
                class="text-white"
                @click="orderRadiologi(item)"
                color="orange"
                fab
              >
                <v-icon>mdi-radioactive</v-icon>
              </v-btn> -->
              </template>
            </v-data-table>
            <div class="row align-center pb-3 m">
              <div class="col-md-6 col-12 order-md-0 order-1 pt-0 pt-md-4">
                <v-data-footer
                  class="float-left"
                  :pagination="dataTable.pagination"
                  :prev-icon="null"
                  :next-icon="null"
                  :first-icon="null"
                  :last-icon="null"
                  :items-per-page-options="[10, 15, 50, 100, -1]"
                  :options.sync="dataTable.options"
                />
              </div>
              <div class="col-md-6 col-12 order-md-1 order-0 mt-4 pb-0 pb-md-4">
                <v-pagination
                  v-model="dataTable.config.table.page"
                  :length="dataTable.config.table.pageCount"
                  total-visible="7"
                  circle
                />
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </fieldset>
    <Dialog
      ref="cek"
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
      :memberCard="memberCardV"
    />

  </v-container>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
import Dialog from '@/components/ComponentAlertBpjs'
import store from '@/store/index.js'
// eslint-disable-next-line no-unused-vars
import firebases from './firebase'

export default {
  components: {
    Dialog

  },
  data () {
    return {
      sudah_di_kerjakan: [],
      sudah_dinput: [],
      tab: '',
      myStylePasien: {
        backgroundColor: '#16a085'
      },
      rentangbulan: [
        '1 Minggu',
        '1 BULAN',
        '2 BULAN',
        '3 BULAN',
        '6 BULAN',
        '1 TAHUN'
      ],

      // labor
      dialogShowLabor: false,
      dialogTitleLabor: 'Permintaan Laborat',
      dialogProgressLabor: false,
      dialogDisableNBtnLabor: false,
      dialogDisablePBtnLabor: false,

      dialogActionNBtnLabor: () => {
        this.dialogShowLabor = false
      },
      dialogActionPBtnLabor: () => {},

      dialogShow: false,
      dialogTitle: 'Check Kartu BPJS',
      dialogMessage: 'Pasien BPJS KESEHATAN MANDIRI',
      dialogProgress: false,
      tglSekarang: '',
      memberCardV: '',
      dialogDisableNBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      // Dialog Radiologi
      dialogActionNBtnRadiologi: () => {
        this.dialogShowRadiologi = false
      },
      dialogActionPBtnRadiologi: () => {},

      dialogShowRadiologi: false,
      dialogTitleRadiologi: 'Form Permintaan Radiologi',
      dialogMessageRadiologi: 'Pasien BPJS KESEHATAN MANDIRI',
      dialogProgressRadiologi: false,
      memberCardRadiologi: '',
      dialogDisableNBtnRadiologi: false,
      dialogDisablePBtnRadiologi: false,
      patient: [],
      iReadonly: false,
      pegawai: '',
      subUnitId: '',
      employeeId: '',
      dataTable: {
        isLoading: false,
        headerData: [
          { text: 'Aksi', value: 'aksi', width: '120px', align: 'center' },
          {
            text: 'Antrian',
            align: 'center',
            value: 'q_number',
            width: '110px'
          },
          {
            text: 'No.RM',
            value: 'medrec_no',
            width: '100px',
            align: 'center'
          },
          {
            text: 'Nama Pasien',
            value: 'patient_name',
            width: '380px'
            // align: 'center'
          },
          {
            text: 'No.SEP',
            value: 'refer_no',
            width: '150px',
            align: 'center'
          },
          { text: 'Cara Bayar', value: 'pay_by_name', width: '290px' },
          { text: 'Nama Poli', value: 'unit_name', width: '200px' },
          { text: 'Nama Dokter', value: 'staff_name', width: '490px' },

          // { text: 'No. Asuransi/Bpjs', value: 'member_card', width: '150px' },

          { text: 'No. Register', value: 'folio_id', width: '100px' },
          { text: 'No. Telepon', value: 'handphone', width: '290px' }
        ],
        search: '',
        tglPelayanan: '',
        datas: [],
        config: {
          table: {
            page: 1,
            pageCount: 0,
            sortBy: ['q_number'],
            sortDesc: [],
            itemsPerPage: 300,
            itemKey: 'id'
          }
        },
        serverLength: 1,
        pagination: {},
        options: {}
      }
    }
  },
  mounted () {
    // localStorage.setItem('drawer', 'false')
    // localStorage.setItem('minivar', 'false')
  },
  methods: {
    kirim (item) {
      const folioId = window.btoa(item.folio_id)
      const medrecNo = window.btoa(item.medrec_no)
      var url = `p1=${medrecNo}&p2=${folioId}`
      window.open(`https://www.rs-syafira.com/rating?${url}`, '_blank').focus()
    },
    panggil (item) {
      console.log(item)
      var ini = this
      // eslint-disable-next-line no-undef
      $.ajax({
        url:
          baseUrl +
          'antrian-panggil/count-pasien-selesai?sub_unit_id=' +
          item.sub_unit_id,
        type: 'GET',
        // dataType: 'json', // added data type
        success: function (res) {
          // console.log(res);
          // alert(res);
          if (res.con === true) {
            // eslint-disable-next-line camelcase, no-unused-vars
            var count_pasien_selesai_temp = '-'
            if (res.con === true) {
              // alert(res.results.selesai)
              // eslint-disable-next-line camelcase, no-unused-vars
              count_pasien_selesai_temp = res.results.selesai
            } else {
              errorMsg('Gagal Ambil Data Count "Pasien Selesai":' + res.msg)
            }
          } else {
            errorMsg('Gagal Ambil Data Count "Pasien Selesai":' + res.msg)
          }
          // eslint-disable-next-line camelcase, no-unused-vars
          console.log(count_pasien_selesai_temp + ' =>> HASIL')

          ini.subPanggil(item, count_pasien_selesai_temp)

          // resetBtnLoading(btn, html)
        },
        error: function () {
          // resetBtnLoading(btn, html)
          ini.subPanggil(item, '-')
          errorMsg('Gagal Ambil Data Count "Pasien Selesai"')

          // toastr.error('Gagal Ambil Data Count "Pasien Selesai"')
        },
        complete: function () {
          // resetBtnLoading(btn, html)
        }
      })
    },

    // eslint-disable-next-line camelcase
    subPanggil (item, count_pasien_selesai_temp) {
      console.log(item + 'KE SINI')
      this.pushAntreanFirebase(item, 'panggil', count_pasien_selesai_temp)
      this.updateQueue(item.folio_id, '', 'panggil')
    },
    pushAntreanFirebase (item, modality, count_pasien_selesai = '-') {
      // eslint-disable-next-line no-unused-vars
      var database = firebases.database()
      console.log(modality + '=> Modality')

      console.log(item.location_id + 'LOKASI')

      // eslint-disable-next-line camelcase, no-unused-vars
      var folio_id_ = item.folio_id
      // eslint-disable-next-line camelcase, no-unused-vars
      var nama_pasien = item.patient_name
      // eslint-disable-next-line camelcase, no-unused-vars
      var no_registrasi = item.registration_no
      // eslint-disable-next-line camelcase, no-unused-vars
      var no_antrian = item.q_number
      // eslint-disable-next-line camelcase, no-unused-vars
      var no_antrian_speech = item.q_number.replace(/(.{1})/g, '$1,. ')
      // eslint-disable-next-line camelcase, no-unused-vars
      var sub_unit_id = item.sub_unit_id
      // eslint-disable-next-line camelcase, no-unused-vars
      var nama_dokter = item.sub_unit_name
      // eslint-disable-next-line camelcase, no-unused-vars
      var nama_dokter_profile = item.staff_name
      // eslint-disable-next-line camelcase, no-unused-vars
      var location_id = item.location_id
      // eslint-disable-next-line camelcase, no-unused-vars
      var location_name = ''
      // eslint-disable-next-line camelcase, no-unused-vars
      var nama_dokter_speech = item.staff_name.split(',')[0]

      // eslint-disable-next-line camelcase, no-unused-vars, no-undef
      var generated_firebase =
        // eslint-disable-next-line camelcase
        database.ref('panggil/' + location_id + '/').push().key +
        '~' +
        // eslint-disable-next-line camelcase
        folio_id_ +
        '~' +
        modality

      // console.log(generated_firebase)
      const data = {
        nama: nama_pasien,
        folio_id: folio_id_,
        no_registrasi: no_registrasi,
        no_antrian: no_antrian,
        no_antrian_speech: no_antrian_speech,
        nama_dokter: nama_dokter,
        nama_dokter_speech: nama_dokter_speech,
        nama_dokter_profile: nama_dokter_profile,
        sub_unit_id: sub_unit_id,
        location_id: location_id,
        location_name: location_name,
        modality: modality,
        count_pasien_selesai: count_pasien_selesai,
        // eslint-disable-next-line no-undef
        speechperson: 'Google Bahasa Indonesia',
        // speechperson: "",
        timestamp: Date.now(),
        ymd: this.getDateNowYmd()
      }
      console.log('DATA => ' + JSON.stringify(data))

      // eslint-disable-next-line camelcase
      database
        // eslint-disable-next-line camelcase
        .ref('panggil/' + item.location_id + '/' + generated_firebase)
        .set(data)
    },
    selesai (item) {
      this.pushAntreanFirebase(item, 'selesai')
      this.updateQueue(item.folio_id, '', 'selesai')
    },
    batal () {},

    getDateNowYmd () {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      var yyyy = today.getFullYear()

      today = yyyy + '-' + mm + '-' + dd
      return today
    },

    // eslint-disable-next-line camelcase
    updateQueue (folio_id, html, modality) {
      // var btn = $("#buttonKonfirmasi");
      // var html = btn.html();
      // eslint-disable-next-line no-undef
      $.ajax({
        type: 'post',
        url: baseUrl + 'antrian-panggil/update-queue',
        data: {
          modality: modality, // no_rujukan_input
          folio_id: folio_id
        },
        complete: function () {
          // isKonfirmasi = false;s
          // $("#kartu_no").select();
        },
        success: function (result) {
          if (result.con === true) {
            successMsg(result.results)
          } else {
          }
        },
        error: function () {
          // toastr.error('Gagal Koneksi Server')
        }
      })
    },
    goToProfilPasien (v) {
      console.log(v.unit_id)

      const data = ['106', '46']

      console.log(data.includes(v.unit_id))

      if (!data.includes(v.unit_id)) {
        if (v.pay_by_classification === 'BPJS') {
          if (!v.refer_no) {
            errorMsg(
              'SEP BELUM ADA TIDAK BISA DIPERIKSA SILAHKAN HUBUNGI BAGIAN ADMISION'
            )
            return
          }
          if (v.refer_no === 'SEP BELUM ADA') {
            errorMsg(
              'SEP BELUM ADA TIDAK BISA DIPERIKSA SILAHKAN HUBUNGI BAGIAN ADMISION'
            )
            return
          }
        }
      }
      // console.log(v)
      this.$router.push({
        name: 'ProfilePasien',
        // params: { no_rm: v.medrec_no, folio_id: v.folio_id, registration_id: v.registration_id }
        query: {
          no_rm: v.medrec_no,
          folio_id: v.folio_id,
          registration_id: v.registration_id,
          sub_unit_id: v.sub_unit_id,
          type: 'rawat-jalan'
        }
      })
      store.commit('set_params', [
        v.medrec_no,
        v.folio_id,
        v.registration_id,
        v.sub_unit_id
      ])
      // commit('set_perm', results)

      localStorage.setItem(
        'folioIdYangDipakai',
        JSON.stringify({
          no_rm: v.medrec_no,
          folio_id: v.folio_id,
          registration_id: v.registration_id
        })
      )
      localStorage.removeItem('firebasePasien')

      localStorage.setItem(
        'firebasePasien',
        JSON.stringify({
          no_rm: v.medrec_no,
          folio_id: v.folio_id,
          reg_id: v.registration_id,
          patient_name: v.salutation + v.patient_name,
          antrian: v.q_number
        })
      )
    },
    itemRowBackground (item) {
      console.log('iTEM ' + item.pasien_selesai)

      if (item.pasien_selesai === 2) {
        return 'red lighten-2'
      } else if (item.pasien_selesai === 1) {
        return 'lime lighten-3'
      } else {
        return 'indigo lighten-1'
      }
    },
    checkKartuBpjs (v) {
      this.dialogProgress = true

      this.dialogShow = true
      this.memberCardV = v.member_card
      setTimeout(() => {
        this.$refs.cek.getDataPeserta()
        this.$refs.cek.getSep()
        this.dialogProgress = false
      }, 1000)

      // console.log(this.memberCardV)
      // console.log('KARTU BPJS => ' + v.member_card)
    },
    getColor (v) {
      // return new Promise((resolve, reject) => {
      // })
    },
    getDataPasien (v) {
      this.dataTable.isLoading = true

      // console.log(v)
      const query = new URLSearchParams(v).toString()
      apiKedua
        .get(`emr/get-data-pasien?${query}`)
        .then((response) => {
          this.dataTable.datas = response.data.results
            ? response.data.results.data ?? []
            : []
          this.dataTable.serverLength = response.data.results
            ? parseInt(response.data.results.total) ?? 0
            : 0
          this.isReadonly = false
          this.dataTable.isLoading = false

          // this.sudah_di_kerjakan = response.data.results.sudah_di_kerjakan
          // this.sudah_dinput = response.data.results.sudah_di_input
        })
        .catch((error) => {
          this.isReadonly = false
          this.dataTable.isLoading = false

          console.log(error)
        })
    },
    orderRadiologi (item) {
      // console.log(item)

      setTimeout(() => {
        this.dialogShowRadiologi = true
        this.$refs.radiologi.historyRadiologi(
          item.registration_id,
          item.medrec_no,
          item.folio_id,
          item.sub_unit_id
        )
      }, 1000)
    },
    orderLabor (item) {
      // console.log(item)

      setTimeout(() => {
        this.dialogShowLabor = true
        this.$refs.labor.historyLabor(
          item.registration_id,
          item.medrec_no,
          item.folio_id,
          item.sub_unit_id
        )
      }, 1000)
    },
    jamSekarang () {
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      const formattedToday = dd + '/' + mm + '/' + yyyy
      const todays = yyyy + '-' + mm + '-' + dd
      this.tglSekarang = formattedToday
      this.dataTable.tglPelayanan = todays
    },
    muatulang () {
      this.getDataPasien({
        unit: this.subUnitId,
        id_pegawai: this.employeeId,
        ...this.dataTable.options,
        search: this.dataTable.search,
        tglPelayanan: this.dataTable.tglPelayanan
      })
    }
  },
  watch: {
    'dataTable.options' (a, b) {
      if (this.employeeId) {
        this.getDataPasien({
          unit: this.subUnitId,
          id_pegawai: this.employeeId,
          ...this.dataTable.options,
          search: this.dataTable.search,
          tglPelayanan: this.dataTable.tglPelayanan
        })
      }
    },
    'dataTable.search' (a, b) {
      if (this.dataTable.search.length > 3) {
        this.getDataPasien({
          unit: this.subUnitId,
          id_pegawai: this.employeeId,
          ...this.dataTable.options,
          search: this.dataTable.search,
          tglPelayanan: this.dataTable.tglPelayanan
        })
      }
    },
    'dataTable.tglPelayanan' (a, b) {
      if (this.employeeId) {
        this.getDataPasien({
          unit: this.subUnitId,
          id_pegawai: this.employeeId,
          ...this.dataTable.options,
          search: this.dataTable.search,
          tglPelayanan: this.dataTable.tglPelayanan
        })
      }
    }
  },
  created () {
    this.jamSekarang()
    if (localStorage.getItem('user')) {
      setTimeout(() => {
        this.pegawai = JSON.parse(localStorage.getItem('user'))
        this.subUnitId = this.pegawai.sub_unit_id
        this.employeeId = this.pegawai.employee_id

        // console.log(this.employeeId)
        if (this.employeeId) {
          this.getDataPasien({
            unit: this.subUnitId,
            id_pegawai: this.employeeId,
            ...this.dataTable.options,
            search: this.dataTable.search,
            tglPelayanan: this.dataTable.tglPelayanan
          })
        }
      }, 350)
    }
  }
}
</script>
<style>
td {
  vertical-align: middle !important;
}
</style>
